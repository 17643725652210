// extracted by mini-css-extract-plugin
export var bodyContainer = "careers-module--bodyContainer--d9b52";
export var bottomContainer = "careers-module--bottomContainer--59abd";
export var container = "careers-module--container--16d3c";
export var description = "careers-module--description--b9051";
export var divider = "careers-module--divider--a2617";
export var icon = "careers-module--icon--2e07a";
export var iconContainer = "careers-module--icon-container--0102d";
export var mainImage = "careers-module--mainImage--49fb0";
export var section1 = "careers-module--section1--06fba";
export var section1body = "careers-module--section1body--49cc0";
export var sectionTitle = "careers-module--section-title--33ee6";
export var title = "careers-module--title--31526";