import React from "react"
import Layout from "../components/layouts/Layout.js"
import { graphql, useStaticQuery, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import * as styles from "../stylesheets/pages/careers.module.scss"

const Careers = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicCareers {
        data {
          section {
            body {
              text
              richText
              html
            }

            title1 {
              text
            }
            image {
              alt
              url
            }
          }
          title {
            text
          }
          image {
            alt
            url
          }
        }
      }
    }
  `).prismicCareers.data

  const section = data.section.map((item, index) => {
    return (
      <div
        className={styles.section1body}
        style={{ background: index == 1 ? "black" : "white" }}
      >
        <div
          className={styles.section1}
          style={{ color: index == 1 ? "white" : "black" }}
        >
          <div className={styles.iconContainer}>
            <img
              src={item.image.url}
              alt="Now hiring remote support technician"
              className={styles.icon}
            />
          </div>

          <h2 className={styles.sectionTitle}>{item.title1.text}</h2>

          <RichText
            render={item.body.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
      </div>
    )
  })
  return (
    <Layout
      title="Careers - TechCollective"
      description="TechCollective is hiring."
    >
      <div className={styles.container}>
        <div>
          <img
            src={data.image.url}
            alt="TechCollective Careers Now Hiring"
            className={styles.mainImage}
          />
          <h1 className={styles.title}>Work With Us</h1>
        </div>
        {section}
      </div>
    </Layout>
  )
}

export default Careers
